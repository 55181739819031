import React, { useState, useEffect } from "react";
import "./footer.css";

import Logo from "../assets/Logo.png";
import PlayStore from "../assets/playstore.png";
import AppStore from "../assets/appstore.png";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import hotel_android_qr from "../assets/yourhotel_android.png";
import hotel_ios_qr from "../assets/yourhotel_ios.png";

import icon_android_qr from "../assets/icon_android.png";
import icon_ios_qr from "../assets/icon_ios.png";
// import ReactGA from "react-ga4";
import { getAnalytics, logEvent } from "@firebase/analytics";

// import Button from "react-bootstrap/Button";

const Footer = (props) => {
  const [hotelCode, setHotelCode] = useState("");
  console.log(hotelCode, "hotelCode");
  const hotelData = useSelector((state) => state.hotel.hoteldata);
  const hotelErr = useSelector((state) => state.hotel.error);
  useEffect(() => {
    //  setLoader(true);
    if (hotelData !== null) {
      //  setLoader(false);
      if (hotelData.data.code == 200) {
        //  setData(hotelData.data.data);

        // if(hotelData.data)
        var hotel_name = hotelData.data.data.meta.find(
          (o) => o.meta_key === "template"
        );

        if (hotel_name) {
          var hotel_value = hotel_name.meta_value;
          setHotelCode(hotel_value);
        } else {
          setHotelCode("");
        }
      }
    } else if (hotelErr !== null) {
      //console.log(props.error, 'netwwork error');
      // swal("Failed!", "Network Error!", "error");
      //  setLoader(false);
    }
  }, [hotelData, hotelErr]);
  const analytics = getAnalytics();

  return (
    <footer>
      <div class="container">
        <div className="column  ">
          <div className="col-md-12 d-flex justify-content-center">
            <div className="logo_number">
              <p style={{ padding: 5, fontSize: 12 }}>Powered By</p>
              {/* <Link to="/"> */}
              <img src={Logo} alt="logo" style={{ width: "65px" }} />
              {/* </Link> */}
              <p style={{ fontSize: 12 }}>TEAM Technology</p>
            </div>
          </div>
          <div
            className="col-md-12 d-flex justify-content-center"
            style={{ marginTop: 10 }}
          >
            <p style={{ fontWeight: "bold", fontSize: 16 }}>
              To Download the full App just tap here or scan the QR Code
            </p>
          </div>
          <div className="col-md-12 d-flex justify-content-center ">
            <div className="btn_store_buttons">
              <div
                className="playstore"
                onClick={() => {
                  logEvent(analytics, "button_clicked", {
                    category: "Button",
                    action: "Button Clicked",
                    button: `Button Clicked Play Store, Time: ${Date.now()} `,
                    value: Date.now(),
                    // topicName: item.title,
                  });
                  // ReactGA.event({
                  //   category: "Play Store button",
                  //   action: "OnClick Play Store button",
                  //   label: "Play Store",
                  //   value: Date.now(),
                  // });
                }}
              >
                <a
                  className="btn_footer_playstore"
                  style={{
                    fontSize: 10,
                    marginLeft: 5,
                    color: "#fff",
                    textDecoration: "none",
                    backgroundColor:
                      hotelCode.toString() == "1"
                        ? "#4da896"
                        : hotelCode.toString() == "2"
                        ? "#8bd12a"
                        : hotelCode.toString() == "3"
                        ? "#0a2665"
                        : "#e78200",
                  }}
                  target="_blank"
                  href={
                    hotelCode.toString() == "1"
                      ? "https://play.google.com/store/apps/details?id=com.iconresorts"
                      : hotelCode.toString() == "2" ||
                        hotelCode.toString() == "3"
                      ? "https://play.google.com/store/apps/details?id=com.yourhotelapp"
                      : null
                  }
                >
                  <img src={PlayStore} alt="logo" style={{ marginRight: 10 }} />
                  Play Store
                </a>
                <img
                  src={
                    hotelCode.toString() == "1"
                      ? icon_android_qr
                      : hotel_android_qr
                  }
                  className="qr_code"
                  alt="logo"
                  style={{ marginLeft: 10 }}
                />
              </div>

              <div
                className="appstore"
                onClick={() => {
                  logEvent(analytics, "button_clicked", {
                    category: "Button",
                    action: "Button Clicked",
                    button: `Button Clicked App Store, Time: ${Date.now()} `,
                    value: Date.now(),
                    // topicName: item.title,
                  });
                }}
              >
                <a
                  style={{
                    fontSize: 10,
                    marginLeft: 5,
                    color: "#fff",
                    textDecoration: "none",
                    backgroundColor:
                      hotelCode.toString() == "1"
                        ? "#4da896"
                        : hotelCode.toString() == "2"
                        ? "#8bd12a"
                        : hotelCode.toString() == "3"
                        ? "#0a2665"
                        : "#e78200",
                  }}
                  className="btn_footer_appstore"
                  target="_blank"
                  href={
                    hotelCode.toString() == "1"
                      ? "https://apps.apple.com/au/app/iconresorts/id6443556233"
                      : hotelCode.toString() == "2" ||
                        hotelCode.toString() == "3"
                      ? "https://apps.apple.com/au/app/your-hotel/id1554876665"
                      : null
                  }
                >
                  <img src={AppStore} alt="logo" style={{ marginRight: 10 }} />
                  App Store
                </a>

                <img
                  src={hotelCode.toString() == "1" ? icon_ios_qr : hotel_ios_qr}
                  alt="logo"
                  className="qr_code"
                  style={{ marginLeft: 10 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    mode: state.mode.themeMode,
  };
};

export default connect(mapStateToProps, {})(Footer);
