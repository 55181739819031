import React from "react";
import Footer from "../footer/footer";

import { connect } from "react-redux";

const Error = (props) => {
  return (
    <div className="error_page">
      {/* <Header /> */}
      {props.error == "true" ? (
        <>
          <div className="inner_body">
            <h2
              className="text-center"
              style={{
                color: "black",
              }}
            >
              404 not found
            </h2>
          </div>
        </>
      ) : null}

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // mode: state.mode.themeMode,
  };
};

export default connect(mapStateToProps, {})(Error);
