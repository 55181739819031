import React from "react";
// import { apiActiveURL, appId, appKey } from "../ApiBaseURL";
// import Featured from "../component/Featured/Featured";
// import Search from "../component/Search/Search";
import Services from "../component/GuestDirectoryServices/Services";
import Footer from "../footer/footer";
import Header from "../Header/Header";
// import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GuestDirectory } from "../Redux/Actions/GuestDirectory";
import { useSelector } from "react-redux";
import { useState } from "react";
import Error from "./Error";

const Home = (props) => {
  // const [directoryData, setDirectoryData] = useState(null);
  const dispatch = useDispatch();
  // const guest = useSelector((state) => state.guest.guestdata);
  const hotelData = useSelector((state) => state.hotel.hoteldata);
  const hotelErr = useSelector((state) => state.hotel.error);
  useEffect(() => {
    if (hotelData != null) {
      const data = {
        list_type: 1,
        hotel_id: hotelData?.data?.data?.id,
      };
      dispatch(GuestDirectory(data));
    } else if (hotelErr != null) {
    }
  }, [hotelData, hotelErr]);

  return (
    <div className="home_body">
      {
        hotelErr == null ? (
          <>
            <Header />

            <div className="inner_body">
              <div className="container">
                <Services />
              </div>
            </div>
            <Footer />
          </>
        ) : (
          <Error error="true" />
          // <Footer />
        )
        // <h3 style={{ textAlign: 'center', paddingTop: 50 }}>No Data Found</h3>
      }
    </div>
  );
};
export default Home;
