import React from "react";
import { apiActiveURL, appId, appKey } from "../ApiBaseURL";
// import Featured from "../component/Featured/Featured";
// import Search from "../component/Search/Search";
// import VideoBox from "../component/VideoList/VideoBox";
import Footer from "../footer/footer";
import Header from "../Header/Header";
import axios from "axios";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { GuestDirectory } from "../Redux/Actions/GuestDirectory";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Weather = (props) => {
  const location = useLocation();

  // const [directoryData, setDirectoryData] = useState(null);
  const [weather, setWeather] = useState(null);
  const [loader, setLoader] = useState(false);

  // const dispatch = useDispatch();
  // const guest = useSelector((state) => state.guest.guestdata);
  // const hotelData = useSelector((state) => state.hotel.hoteldata);
  // const hotelErr = useSelector((state) => state.hotel.error);

  // const data = {
  //   list_type: 1,
  //   hotel_id: 50,
  // };

  const regions = {
    Sydney: "CITY_SYDNEY",
    Melbourne: "CITY_MELBOURNE",
    Brisbane: "CITY_BRISBANE",
    Canberra: "CITY_CANBERRA",
    Darwin: "CITY_DARWIN",
    Hobart: "CITY_HOBART",
    "Gold Coast": "CITY_GOLD_COAST",
    Perth: "CITY_PERTH",
    Adelaide: "CITY_ADELAIDE",
    Perth: "CITY_BYRON_BAY",
    "Hunter Region": "CITY_HUNTER_REGION",
    "Southern Highlands": "CITY_SOUTHERN_HIGHLANDS",
    "Central Coast": "CITY_CENTRAL_COAST",
    "Maclaren Vale": "CITY_MACLAREN_VALE",
    "Tweed Heads": "CITY_TWEED_HEADS",
    "Sunshine Coast": "CITY_SUNSHINE_COAST",
  };
  useEffect(() => {
    Promise.all(fetchWeather());
  }, []);

  const fetchWeather = async () => {
    setLoader(true);
    let region = "";

    // if (props.cityName) {
    // region = props.cityName.split('/');
    region = location.state.state;
    //   if (!region) {
    //     props.setFeedback('YourHotel', 'No Data Found...', true, '');
    //     return null;
    //   }
    // } else {
    //   props.setFeedback('YourHotel', 'No Data Found...', true, '');
    //   return null;
    // }
    region = regions[region];
    if (!region) {
      //   props.setFeedback('YourHotel', 'No Data Found...', true, '');
      return null;
    } else {
      setLoader(true);
      const url = `${apiActiveURL}/get_weather/${region}`;
      const options = {
        method: "GET",
        headers: {
          AppKey: appKey,
          // Token: 'A3Oy18BqjvFPIUTXY94im76ENuh5HVsngbo2lJxkL0reaZftKWcSDQwMpCzR',
          AppId: appId,
        },
        url,
      };
      await axios(options)
        .then((res) => {
          console.log(res.data, "res");
          if (res.data.code === 200) {
            setWeather(res?.data?.data?.channel?.item);
            // console.log(weather[0]['w:current']['@dewPoint'], 'my weather')
            setLoader(false);

            // setLoader(false);
          }

          //   else if (res.data.code === 403) {
          //     props.setFeedback('YourHotel', 'Session Expired. Logging Out', true, '');
          //     setTimeout(() => {

          //       props.setFeedback('', '', false, '');
          //     }, 2000);
          //     handleSessionId(props.userid, props.token, props)
          //   }

          //   else {
          //     console.log('weather', res);
          //     props.setFeedback('YourHotel', 'No Data Found...', true, '');
          //     // setLoader(false);
          //   }
        })
        .catch((error) => {
          //   props.setFeedback('YourHotel', 'No Data Found...', true, '');
          //   setLoader(false);
          console.log("weather", error);
        });
    }
  };

  return (
    <div className="home_body">
      <Header showButton={true} />
      {weather ? (
        <>
          <div className="inner_body">
            {!loader ? (
              <div className="container">
                <div
                  className="circle_div"
                  style={{
                    backgroundColor:
                      location.state.hotelCode == "1"
                        ? "#4da896"
                        : location.state.hotelCode == "2"
                        ? "#e78200"
                        : location.state.hotelCode == "3"
                        ? "#40a8dd"
                        : "#e78200",
                  }}
                >
                  <p>Current Conditions:</p>
                  <p
                    style={{
                      textAlign: "center",
                      paddingBottom: 10,
                      fontSize: 55,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {weather ? (
                      <>
                        {" "}
                        {weather && weather[0]["w:current"]["@temperature"]}°C
                      </>
                    ) : null}
                  </p>
                  <div
                    style={{
                      borderBottomColor: "black",
                      borderBottomWidth: 2,
                      width: "30%",
                      alignSelf: "center",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        display: "flex",
                        paddingVertical: 10,
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 16,
                          color: "black",
                          // fontSize: ''
                        }}
                      >
                        Dew Point:
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 16,
                          color: "black",
                          // fontSize: ''
                        }}
                      >
                        {weather ? (
                          <> {weather[0]["w:current"]["@dewPoint"]}</>
                        ) : null}
                      </p>
                      <p>Relative Humidity</p>
                      <p> {weather[0]["w:current"]["@humidity"]}%</p>
                      <p>Wind Speed:</p>
                      <p>{weather[0]["w:current"]["@windSpeed"]} km/h,</p>
                      <p>Wind Gust:</p>
                      <p>{weather[0]["w:current"]["@windGusts"]} km/h,</p>
                      <p>Wind Direction:</p>
                      <p>{weather[0]["w:current"]["@windDirection"]}</p>
                      <p>Pressure:</p>
                      <p> {weather[0]["w:current"]["@pressure"]} hPa,</p>
                      <p>Rain Since 9AM:</p>
                      <p>{weather[0]["w:current"]["@rain"]}mm</p>
                    </div>
                  </div>
                </div>
                <div className="weather_forcast">
                  <div
                    style={{
                      flexDirection: "row",
                      // marginTop: 20,

                      flexWrap: "wrap",
                      // display: 'flex'
                    }}
                    className="d-flex col-md-12"
                  >
                    <div
                      style={{
                        backgroundColor:
                          location.state.hotelCode == "1"
                            ? "#4eabf0"
                            : location.state.hotelCode == "2"
                            ? "#8bd12a"
                            : location.state.hotelCode == "3"
                            ? "#a9adb0"
                            : "#8bd12a",

                        flexBasis: "48%",
                        height: 120,
                        alignContent: "center",
                        justifyContent: "center",
                        marginRight: 5,
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 22,
                          color: "black",
                        }}
                      >
                        {weather[1]["w:forecast"][0]["@day"]}
                      </p>

                      <p>{weather[1]["w:forecast"][0]["@description"]}</p>

                      <p>
                        {`${weather[1]["w:forecast"][0]["@min"]} - ${weather[1]["w:forecast"][0]["@max"]} `}
                      </p>
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          location.state.hotelCode == "1"
                            ? "#4eabf0"
                            : location.state.hotelCode == "2"
                            ? "#8bd12a"
                            : location.state.hotelCode == "3"
                            ? "#a9adb0"
                            : "#8bd12a",
                        flexBasis: "48%",
                        height: 120,
                        alignContent: "center",
                        justifyContent: "center",
                        marginRight: 5,
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 22,
                          color: "black",
                        }}
                      >
                        {weather[1]["w:forecast"][1]["@day"]}
                      </p>

                      <p>{weather[1]["w:forecast"][1]["@description"]}</p>

                      <p>
                        {`${weather[1]["w:forecast"][1]["@min"]} - ${weather[1]["w:forecast"][1]["@max"]}`}
                      </p>
                    </div>

                    <div
                      style={{
                        backgroundColor:
                          location.state.hotelCode == "1"
                            ? "#4eabf0"
                            : location.state.hotelCode == "2"
                            ? "#8bd12a"
                            : location.state.hotelCode == "3"
                            ? "#a9adb0"
                            : "#8bd12a",
                        flexBasis: "48%",
                        height: 120,
                        alignContent: "center",
                        justifyContent: "center",
                        marginRight: 5,
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 22,
                          color: "black",
                        }}
                      >
                        {weather[1]["w:forecast"][2]["@day"]}
                      </p>

                      <p>{weather[1]["w:forecast"][2]["@description"]}</p>

                      <p>
                        {`${weather[1]["w:forecast"][2]["@min"]} - ${weather[1]["w:forecast"][2]["@max"]}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="loader">
                <Spinner animation="border" variant="dark" />
              </div>
            )}
          </div>
        </>
      ) : (
        <div class="loader">
          <Spinner animation="border" variant="dark" />
        </div>
      )}

      <Footer />
    </div>
  );
};
export default Weather;
