import React, { useEffect, useState } from "react";
import "./header.css";

import { Button, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { ThemeMode } from "../Redux/Actions";
import { Spinner } from "react-bootstrap";

const Header = (props) => {
  const navigate = useNavigate();

  const hotelData = useSelector((state) => state.hotel.hoteldata);
  const hotelErr = useSelector((state) => state.hotel.error);

  const [data, setData] = useState("");
  const [hotelCode, setHotelCode] = useState("");
  const [loader, setLoader] = useState(false);

  // Get Page data useEffect

  useEffect(() => {
    setLoader(true);
    if (hotelData !== null) {
      setLoader(false);
      if (hotelData.data.code == 200) {
        setData(hotelData.data.data);

        var hotel_name = hotelData.data.data.meta.find(
          (o) => o.meta_key === "template"
        );

        if (hotel_name) {
          var hotel_value = hotel_name.meta_value;
          setHotelCode(hotel_value);
        } else {
          setHotelCode("");
        }
      }
    } else if (hotelErr !== null) {
      //console.log(props.error, 'netwwork error');
      // swal("Failed!", "Network Error!", "error");
      setLoader(false);
    }
  }, [hotelData, hotelErr]);

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12">
              <div className="align-right">
                <div className="header_menu">
                  <Navbar expand="lg ">
                    <Navbar.Brand>
                      {/* <div> */}
                      {/* <Link to="/"> */}
                      {loader ? (
                        <>
                          <div class="loader">
                            <Spinner animation="border" variant="dark" />
                          </div>
                          {/* <img src={HeaderLogo} alt="logo" /> */}
                        </>
                      ) : (
                        <>
                          <div
                            className="header_logo_image"
                            style={{
                              background: `url(${data.logo}) no-repeat`,
                            }}
                          >
                            {/* {data.logo ? (
                              <img
                                src={data.logo}
                                alt="logo"
                                width={"100px"}
                                height={"100px"}
                                style={{ marginLeft: "32px" }}
                              />
                            ) : null} */}
                          </div>
                        </>
                      )}
                      {/* </div> */}
                      {/* </Link> */}
                    </Navbar.Brand>
                    <div className="align-right d-flex justify-content-lg-end justify-content-start flex-lg-row flex-row-reverse column !important">
                      <div className="righ_nav_items">
                        <div className="icon_box">
                          {props.showButton == true ? (
                            <>
                              <div
                                className="btn_w"
                                style={{ textAlign: "center" }}
                              >
                                <Button
                                  variant="primary"
                                  className="green_btn_local_weather_3"
                                  // type="submit"
                                  onClick={() => navigate(-1)}
                                  style={{
                                    backgroundColor:
                                      hotelCode.toString() == "1"
                                        ? "#4da896"
                                        : hotelCode.toString() == "2"
                                        ? "#e78200"
                                        : hotelCode.toString() == "3"
                                        ? "#0a2665"
                                        : "#e78200",
                                  }}
                                >
                                  Back
                                </Button>{" "}
                              </div>
                            </>
                          ) : null}

                          {/* {loader ? (
                            <>
                              <div class="loader">
                                <Spinner animation="border" variant="dark" />
                              </div>
                            </>
                          ) : (
                            <>
                              <p>{data.area}</p>
                            </>
                          )} */}

                          {/* <div className="col-md-8"> */}

                          {/* </div> */}
                          {/* <p
                          style={{
                            color: props.mode ? "" : styles.colorwhite,
                          }}
                        >
                          {props.mode ? "Dark" : "Light"}

                          <button
                            style={{
                              display: showMode === false ? "block" : "none",
                            }}
                            className={`toggle_button ${
                              props.mode ? "toggle_button_light" : ""
                            }`}
                            onClick={() => {
                              HandlTheme();
                            }}
                          ></button>
                        </p>
                        <p
                          style={{
                            color: props.mode ? "" : styles.colorwhite,
                          }}
                        >
                          {props.mode ? "Dark" : "Light"}

                          <button
                            style={{
                              display: showMode === false ? "block" : "none",
                            }}
                            className={`toggle_button ${
                              props.mode ? "toggle_button_light" : ""
                            }`}
                            onClick={() => {
                              HandlTheme();
                            }}
                          ></button>
                        </p> */}
                        </div>
                        {/* <div className="row">
                          <div className="col-md-8">
                            <form
                              class="example"
                              action=""
                              // style="margin:auto;max-width:300px"
                            >
                              <input
                                type="text"
                                placeholder="Search.."
                                name="search2"
                              />
                              <button type="submit">
                                <i class="fa fa-search">search</i>
                              </button>
                            </form>
                          </div>
                          <div className="col-md-4">
                            <Button
                              variant="primary"
                              className="help_btn"
                              type="submit"
                            >
                              Help
                            </Button>
                          </div>
                        </div> */}
                        {/* <div className="icon_box_two">
                          
                          <Form.Group>
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                setSearchData(e.target.value);
                              }}
                              value={searchData}
                            />
                           
                          </Form.Group>
                          <Button
                            variant="primary"
                            className="help_btn"
                            type="submit"
                          >
                            Help
                          </Button>
                        </div> */}
                      </div>
                    </div>
                  </Navbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="inner_banner">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="header_text">
                <h4
                  style={{
                    marginBottom: 5,
                  }}
                >
                  Welcome to {data.name}
                </h4>
                <h4>Guest Information</h4>
              </div>
              {/* <div> */}
              {/* <div className="btn_w" style={{ textAlign: "center" }}>
                <Button
                  variant="primary"
                  className="green_btn_local_weather_2"
                  // type="submit"
                  onClick={() => navigate("/weather")}
                >
                  Local Weather
                </Button>{" "}
              </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    mode: state.mode.themeMode,
  };
};

export default connect(mapStateToProps, {
  ThemeMode,
})(Header);
