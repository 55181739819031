import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Error from "../Pages/Error";
// import Services from "../Pages/Services";
// import Contact from "../Pages/Contact";
import GuestDetail from "../Pages/GuestDetail";
import Weather from "../Pages/Weather";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Hotel } from "../Redux/Actions";
// import Footer from "../footer/footer";

const AppRoutes = (props) => {
  const dispatch = useDispatch();
  // const hotelData = useSelector((state) => state.hotel.hoteldata);

  useEffect(() => {
    const str = window.location.pathname.split("/")[1];
    let data = {
      key: str,
    };

    dispatch(Hotel(data));
  }, []);
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Error error="false" />} />

          <Route path="/:id" element={<Home />} />
          <Route path="/:id/:productSlug" element={<GuestDetail />} />

          <Route path="/weather" element={<Weather />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};
export default AppRoutes;
