import React from "react";
import { apiActiveURL, appId, appKey } from "../ApiBaseURL";
// import Featured from "../component/Featured/Featured";
// import Search from "../component/Search/Search";
// import VideoBox from "../component/VideoList/VideoBox";
import Footer from "../footer/footer";
import Header from "../Header/Header";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { GuestDirectory } from "../Redux/Actions/GuestDirectory";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const GuestDetail = (props) => {
  const location = useLocation();

  const [titleData, setTitleData] = useState("");
  // const [titleLocData, setTitleLocData] = useState("");
  const [path, setPath] = useState("");
  const [id, setId] = useState(null);
  const [detail, setDetail] = useState("");

  // const dispatch = useDispatch();
  // const guest = useSelector((state) => state.guest.guestdata);
  // const hotelData = useSelector((state) => state.hotel.hoteldata);

  // setDirectoryData(guest)
  // const myparams = useParams();
  // console.log(window.location.pathname.split("/")[2], "sadmkasdmksda");

  useEffect(() => {
    // return;
    if (location?.state !== null) {
      console.log("yes");
      setDetail(location?.state?.description);
      setTitleData(location?.state?.title);
    } else {
      console.log("no");
      setPath(window.location.pathname.split("/")[2]);
      const str = window.location.pathname.split("/")[1];
      setId(str.slice(1, -1));
      handleData(str.slice(1, -1), window.location.pathname.split("/")[2]);
    }
  }, []);

  const handleData = async (topicId, title) => {
    const url = `${apiActiveURL}/service_title/${topicId}/${title}`;
    const options = {
      method: "GET",
      headers: {
        AppKey: appKey,
        // Token: 'A3Oy18BqjvFPIUTXY94im76ENuh5HVsngbo2lJxkL0reaZftKWcSDQwMpCzR',
        AppId: appId,
      },
      url,
    };
    await axios(options)
      .then((res) => {
        if (res.data.code === 200) {
          setTitleData(res.data.data.title);
          setDetail(res.data.data.description);
          // setWeather(res?.data?.data?.channel?.item);
          // console.log(weather[0]['w:current']['@dewPoint'], 'my weather')
          // setLoader(false);

          // setLoader(false);
        } else {
        }

        //   }
      })
      .catch((e) => {
        console.log(e, "e");
      });
  };

  // const data = {
  //   list_type: 1,
  //   hotel_id: 50,
  // };
  // const [services, setServices]

  return (
    <div className="home_body">
      <Header showButton={true} />
      <div className="inner_banner_title">
        {titleData ? (
          <>
            <h6>{titleData}</h6>
          </>
        ) : null}
      </div>
      <div className="inner_body">
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: detail }}></div>
        </div>

        {/* <Featured /> */}
      </div>
      <Footer />
    </div>
  );
};
export default GuestDetail;
