import "./App.css";
import React, { useState, useEffect } from "react";
import "./Style/main.css";
import AppRoutes from "./Routes/Routes";
import { connect, useSelector } from "react-redux";
import AppBg from "./assets/app_bg.png";
import IconBg from "./assets/icon_bg.png";
import HotelBlue from "./assets/hotelblue.png";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAFlSAQ45W6iLVNcm3qnPPYdJ5IHBTxkOc",
  authDomain: "yourhotelwebapp.firebaseapp.com",
  projectId: "yourhotelwebapp",
  storageBucket: "yourhotelwebapp.appspot.com",
  messagingSenderId: "427436623161",
  appId: "1:427436623161:web:22879f18ceeda049e5d4e8",
  measurementId: "G-K20HNE2PFZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
ReactGA.initialize("G-HKXVP2J90C");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
  title: "After scan Qr Code url with Hotel Id",
});

function App() {
  const [data, setData] = useState("");
  const [hotelCode, setHotelCode] = useState("");

  const hotelData = useSelector((state) => state.hotel.hoteldata);
  const hotelErr = useSelector((state) => state.hotel.error);

  const analytics = getAnalytics();
  useEffect(() => {
    if (hotelData !== null) {
      //  setLoader(false);
      if (hotelData.data.code == 200) {
        setData(hotelData.data.data);

        logEvent(analytics, "hotel_detail", {
          category: "Hotel Name",
          action: "Hotel  ID & Name",
          hotel_details: `Hotel Name: ${hotelData.data.data.name}, Hotel Id: ${
            hotelData.data.data.id
          }, Time: ${Date.now()}`,
          hotel_details_timestamped: Date.now(),
        });
        var hotel_name = hotelData.data.data.meta.find(
          (o) => o.meta_key === "template"
        );

        if (hotel_name) {
          var hotel_value = hotel_name.meta_value;
          setHotelCode(hotel_value);
        } else {
          setHotelCode("");
        }
      }
    } else if (hotelErr !== null) {
    }
  }, []);

  return (
    <div
      className="App"
      style={{
        backgroundImage:
          hotelCode.toString() == "1"
            ? `url(${IconBg})`
            : hotelCode.toString() == "2"
            ? `url(${AppBg})`
            : hotelCode.toString() == "3"
            ? `url(${HotelBlue})`
            : `url(${AppBg})`,
      }}
    >
      <AppRoutes />
    </div>
  );
}

export default App;
