import React, { useState } from "react";

// import WhatsOn from "../../assets/whatson.png";
// import Offers from "../../assets/offers.png";
// import Cafes from "../../assets/cafes.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ResetGuestDirectory } from "../../Redux/Actions";
import { Button, Spinner } from "react-bootstrap";

import { Hotel } from "../../Redux/Actions";
import Modal from "react-bootstrap/Modal";
import { getAnalytics, logEvent } from "@firebase/analytics";

const Services = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const guest = useSelector((state) => state.guest.guestdata);
  const guestErr = useSelector((state) => state.guest.error);
  const hotelData = useSelector((state) => state.hotel.hoteldata);
  const hotelErr = useSelector((state) => state.hotel.error);

  // const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setloader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [hotelCode, setHotelCode] = useState("");

  // WhatsOn Modal states and functions
  const [showWhatsOn, setShowWhatsOn] = useState(false);
  const CloseWhatsOn = () => setShowWhatsOn(false);
  const ShowWhatsOn = () => setShowWhatsOn(true);

  // Promotions and offers states and functions
  const [showPromotions, setShowPromotions] = useState(false);
  const ClosePromotions = () => setShowPromotions(false);
  const ShowPromotions = () => setShowPromotions(true);

  // Cafes and Restaurants states and functions
  const [showCafes, setShowCafes] = useState(false);
  const CloseCafes = () => setShowCafes(false);
  const ShowCafes = () => setShowCafes(true);
  const productSlug = useParams();
  const myparams = useParams();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    // params.get(myparams.id);
    let urlKey = myparams.id;
    let data = {
      key: urlKey,
    };

    dispatch(Hotel(data));
  }, []);

  useEffect(() => {
    setloader(true);
    if (guest !== null) {
      if (guest?.data?.code == 200) {
        setData(guest?.data?.data);
        setloader(false);
      } else if (guest?.data?.code == 403) {
        setloader(false);
        setErrorMsg("Session Expired");
        // console.log(guest, 'errrrrr')
      }
    } else if (guestErr !== null) {
      setErrorMsg(guestErr);
      setloader(false);
    }
    // setloader(false);
    return () => {
      dispatch(ResetGuestDirectory);
    };
  }, [guest, guestErr]);

  useEffect(() => {
    //  setLoader(true);
    if (hotelData !== null) {
      //  setLoader(false);
      if (hotelData.data.code == 200) {
        //  setData(hotelData.data.data);
        console.log(hotelData.data.data.name, "hotelData.data.data.name");
        // if(hotelData.data)
        var hotel_name = hotelData.data.data.meta.find(
          (o) => o.meta_key === "template"
        );

        if (hotel_name) {
          var hotel_value = hotel_name.meta_value;
          setHotelCode(hotel_value);
        } else {
          setHotelCode("");
        }
      }
    } else if (hotelErr !== null) {
      //console.log(props.error, 'netwwork error');
      // swal("Failed!", "Network Error!", "error");
      //  setLoader(false);
    }
  }, [hotelData, hotelErr]);

  const handleServices = (item) => {
    const analytics = getAnalytics();

    // console.log(item, "clicked service");
    let val = item.title;
    let newVal = val.replace(/[^a-zA-Z ]/g, "");
    logEvent(analytics, "topic_selected", {
      category: "Topic",
      action: "Topic Selected",
      topic_detail: `Topic Selected: ${item.title}, Hotel Name: ${
        hotelData?.data?.data?.name
      }, Time: ${Date.now()} `,
      value: Date.now(),
      // topicName: item.title,
    });
    // console.log(newVal, "new VAL");
    navigate(`/${myparams.id}/${newVal}`, {
      state: item,
    });
  };

  return (
    <>
      <div className="filter">
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            {/* Categories Screen */}
            <div className="col-lg-4 col-md-3  mt-3">
              <a
                href="#"
                className="filter_data"
                style={{
                  backgroundColor:
                    hotelCode.toString() == "1"
                      ? "#4eabf0"
                      : hotelCode.toString() == "2"
                      ? "#e78200"
                      : hotelCode.toString() == "3"
                      ? "#a9adb0"
                      : "#e78200",
                }}
                onClick={() => ShowWhatsOn()}
              >
                {/* <div className="data_img">
                <img src={WhatsOn} alt="logo" />
              </div> */}

                <div className="data_text">
                  <h6>{"Whats On"}</h6>

                  {/* <div className="arrow_icon">
                <FaArrowRight />
              </div> */}
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-3 mt-3">
              <a
                href="#"
                className="filter_data"
                style={{
                  backgroundColor:
                    hotelCode.toString() == "1"
                      ? "#4eabf0"
                      : hotelCode.toString() == "2"
                      ? "#e78200"
                      : hotelCode.toString() == "3"
                      ? "#a9adb0"
                      : "#e78200",
                }}
                onClick={() => ShowPromotions()}
              >
                {/* <div className="data_img">
                <img src={Offers} alt="logo" />
              </div> */}
                <div className="data_text">
                  <h6>Promotions & Offers</h6>

                  {/* <div className="arrow_icon">
                <FaArrowRight />
              </div> */}
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-3 mt-3">
              <a
                href="#"
                className="filter_data"
                style={{
                  backgroundColor:
                    hotelCode.toString() == "1"
                      ? "#4eabf0"
                      : hotelCode.toString() == "2"
                      ? "#e78200"
                      : hotelCode.toString() == "3"
                      ? "#a9adb0"
                      : "#e78200",
                }}
                onClick={() => ShowCafes()}
              >
                {/* <div className="data_img">
                <img src={Cafes} alt="logo" />
              </div> */}
                <div className="data_text">
                  <h6>Cafes & Restaurants</h6>

                  {/* <div className="arrow_icon">
                <FaArrowRight />
              </div> */}
                </div>
              </a>
            </div>
            {/* <div className="col-lg-4 col-xs-6 mt-3"> */}

            {/* </div> */}
          </div>
          <div
            className="btn_w"
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            <Button
              variant="primary"
              className="green_btn_local_weather_2"
              style={{
                backgroundColor:
                  hotelCode.toString() == "1"
                    ? "#4da896"
                    : hotelCode.toString() == "2"
                    ? "#e78200"
                    : hotelCode.toString() == "3"
                    ? "#0a2665"
                    : "#e78200",
              }}
              // type="submit"
              // 4da896
              onClick={() =>
                navigate("/weather", {
                  state: {
                    state: hotelData?.data?.data?.area,
                    hotelCode: hotelCode,
                  },
                })
              }
            >
              Local Weather
            </Button>{" "}
          </div>
          {/* Guest directory services */}
          <div className="api_value_box">
            <div
              className="row"
              style={{
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              {!loader ? (
                data.length > 0 ? (
                  data &&
                  data?.map((item, index) => (
                    <div className="col-lg-3 col-xs-6 mt-4">
                      <a
                        href="#"
                        className="filter_data_api"
                        key={index}
                        // onClick={() =>
                        //   navigate(`/${myparams.id}/${item.title}`, {
                        //     state: item,
                        //   })
                        // }
                        onClick={() => {
                          handleServices(item);
                        }}
                      >
                        <div className="data_text_api">
                          <h6>{item.title}</h6>
                        </div>
                      </a>
                    </div>
                  ))
                ) : (
                  <>
                    {/* <h4 style={{ textAlign: "center" }}>No Data Found</h4> */}
                    <Spinner animation="border" variant="dark" />
                  </>
                )
              ) : (
                <div class="loader">
                  <Spinner animation="border" variant="dark" />
                </div>
              )}
              {errorMsg ? (
                <p style={{ textAlign: "center" }}>{errorMsg}</p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="modal_body">
        {/* Whats On Modal */}
        <Modal show={showWhatsOn} onHide={CloseWhatsOn}>
          <Modal.Header>
            <Modal.Title>Whats On</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              There are so many exciting things to do locally, from major events
              unique to the region, all the way through to special and
              lesser-known spots to explore, all covered in the growing
              playground housed in our App.
            </p>
            <p>
              Here, you can find a curated selection of great things to do to
              make the most of your stay with us. Included are over 200 tours,
              attraction and activities.
            </p>
            <p>
              Download our App simply and easily by tapping the Play Store or
              App Store button below!
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="close_btn_modal"
              onClick={CloseWhatsOn}
              style={{
                backgroundColor:
                  hotelCode.toString() == "1"
                    ? "#4eabf0"
                    : hotelCode.toString() == "2"
                    ? "#e78200"
                    : hotelCode.toString() == "3"
                    ? "#0a2665"
                    : "#e78200",
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Promotions and Offers Modal */}
        <Modal show={showPromotions} onHide={ClosePromotions}>
          <Modal.Header>
            <Modal.Title>Promotions & Offers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              We have identified a range of special promotions, discounted
              deals, free offers and unique experience that can be found on our
              App. We strive to have something for everyone to enjoy. Our
              partners are releasing new promotions all the time, which keeps it
              fresh, and exciting.
            </p>
            <p>
              Download our App by tapping the App Store or Play Store button
              below to see all the promotions & offers that are available.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="close_btn_modal"
              style={{
                backgroundColor:
                  hotelCode.toString() == "1"
                    ? "#4eabf0"
                    : hotelCode.toString() == "2"
                    ? "#e78200"
                    : hotelCode.toString() == "3"
                    ? "#0a2665"
                    : "#e78200",
              }}
              onClick={ClosePromotions}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Cafes and Restaurants Modal */}
        <Modal show={showCafes} onHide={CloseCafes}>
          <Modal.Header>
            <Modal.Title>Cafes & Restaurants</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Download our App to find out about every single restaurant, café,
              bar & eatery local to us. We are updating these all the time, so
              you won’t miss out on what’s new.
            </p>
            <p>
              You can have a look at menus, find out the restaurant location and
              make table reservations all directly from the App – plus special
              promotions, meal deals and offers. Want to see what’s available in
              other parts of local region? We have you covered there too!
            </p>
            <p>
              Download our App simply and easily by tapping the Play Store or
              App Store button below.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="close_btn_modal"
              onClick={CloseCafes}
              style={{
                backgroundColor:
                  hotelCode.toString() == "1"
                    ? "#4eabf0"
                    : hotelCode.toString() == "2"
                    ? "#e78200"
                    : hotelCode.toString() == "3"
                    ? "#0a2665"
                    : "#e78200",
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Services;
