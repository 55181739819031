import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const Hotel = (data) => {
  var config = {
    url: `${apiActiveURL}/hotel/${data.key}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        if (res.data.code === 403) {
          GetHotelFail(dispatch);
        } else {
          GetHotelSuccess(dispatch, res);
        }
      })
      .catch((e) => {
        console.log(e, "fail");
        GetHotelFail(dispatch);
      });
  };
};

export const ResetGetHotel = () => {
  return (dispatch) => {
    dispatch({ type: "GET_HOTEL_SUCCESS", payload: null });
  };
};

const GetHotelSuccess = (dispatch, res) => {
  dispatch({ type: "GET_HOTEL_SUCCESS", payload: res });
};

const GetHotelFail = (dispatch) => {
  dispatch({ type: "GET_HOTEL_FAIL", payload: "Something Went Wrong" });
};
